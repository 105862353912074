import Vue from 'vue'
import mobileDetect from "mobile-device-detect";
Vue.mixin({
  data() {
    return {
      notAllowedCharacters: '[`~,<>;:"!?%&¤#/\\[]|{}()+]' + "'",      
      teams: {
        theme: '',
        context: null
      },
      currentDevice: mobileDetect,
      stateOptions: [
        { value: 0, text: this.$t("STATUS_INACTIVE") },
        { value: 1, text: this.$t("STATUS_ACTIVE") },
      ],
      yesnoOptions: [
        { value: 0, text: this.$t("NO") },
        { value: 1, text: this.$t("YES") },
      ]
    };
  },
  methods: {
    IsMessage(type) {
      if (
        type == "message" ||
        type == "response" ||
        type == "creator-response" ||
        type == "link-response" ||
        type == "creator-link-response" ||
        type == "fax-message" ||
        type == "sdk-message"
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsExternalMessage(type) {
      if (
        type == "fax-message" ||
        type == "sdk-message"
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsMeeting(type) {
      if (
        type == "teams-meeting" ||
        type == "jitsi-meeting" ||
        type == "jitsi-secure-meeting" ||
        type == "nuiteq-meeting"
      ) {
        return true;
      } else {
        return false;
      }
    },
    IsSefosMessage(type) {
      if (
        type == "message" ||
        type == "response" ||
        type == "creator-response" ||
        type == "link-response" ||
        type == "creator-link-response"
      ) {
        return true;
      } else {
        return false;
      }
    },
    YesNo(val) {
      return val == 1 ? this.$t('YES') : this.$t('NO');
    },
    validatePhone(phone)
    {
      //let reg_phone = /^\+[1-9]\d{1,14}$/;
      var reg_phone = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      return reg_phone.test(phone);
    },
    validateEmail(email) {
      let reg_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      let validation = reg_email.test(email);
      return validation;
    },
    validatePNum(sPNum) {
      sPNum = sPNum.replace("-", "");
      var numbers = sPNum.match(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/);
      var checkSum = 0;
      if (!this.isDate(sPNum.substring(0, 4), sPNum.substring(4, 6), sPNum.substring(6, 8))) {
        return false;
      }
      if (numbers == null) { return false; }
      var n;
      for (var i = 3; i <= 12; i++) {
        n = parseInt(numbers[i]);
        if (i % 2 == 0) {
          checkSum += n;
        } else {
          checkSum += (n * 2) % 9 + Math.floor(n / 9) * 9
        }
      }
      if (checkSum % 10 == 0) { return true; }
      return false;
    },
    validateLettersAndNumbers(string)
    {
      if (/[`~,<>;':"!?%&¤#/[\]|{}+]/.test(string)) {
        return false;
      }else{
        return true;
      }
    },
    isLettersAndNumbers(e) {
      let char = String.fromCharCode(e.keyCode); 
      if (/[`~,<>;':"!?%&¤#/[\]|{}+]/.test(char)) {
        e.preventDefault();
      }
    },
    Truncate(information, length)
    {
      if (information.length > length) {
        return information.substr(0, length - 2) + "..";
      } else {
        return information;
      }
    },
    mbDescription(mb) {
      if (mb === 0) return mb;
      if(mb >= 1000){
        return (mb/1000) + "GB";
      }
      return mb + "MB";
    },
    bytesToSize(bytes, decimals = 2) {
      if (bytes === 0) return "0";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    downloadBlob(blob, name) {
      //console.log(name);
      if (this.currentDevice.isMobile && this.currentDevice.isChrome && this.currentDevice.isIOS) {
        //isChromeIos
        var reader = new FileReader();
        reader.onloadend = function () {
          window.open(reader.result, "_self");
        }
        reader.readAsDataURL(blob);
      } else {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, name);
        } else {
          //console.log(name);
          let $link = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          $link.setAttribute("target", "_blank");
          $link.setAttribute("href", url);
          $link.setAttribute("download", name);
          $link.style.visibility = "hidden";
          document.body.appendChild($link);
          $link.click();
          document.body.removeChild($link);
        }
      }
    },
    getYear(y) {
      return (y < 1000) ? y + 1900 : y;
    },
    isDate(year, month, day) {
      month = month - 1;
      var tmpDate = new Date(year, month, day);
      if ((this.getYear(tmpDate.getYear()) == year) &&
        (month == tmpDate.getMonth()) &&
        (day == tmpDate.getDate()))
        return true;
      else
        return false;
    },
    FormatDateTime(datetime) {
      return this.$luxonDateTime.fromISO(datetime).toLocaleString(this.$luxonDateTime.DATETIME_MED)
    },
    FormatDate(datetime) {
      return datetime.toLocaleString(this.$luxonDateTime.DATETIME_MED)
    },
    FormatDateTimeRelative(datetime) {
      return this.$luxonDateTime.fromISO(datetime).toRelative()
    },
    PrintInformation(htmlContent)
    {
      let sefosUrl = window.location.origin;
      var css = [];
      for (var i=0; i<document.styleSheets.length; i++)
      {
          var sheet = document.styleSheets[i];
          var rules = ('cssRules' in sheet)? sheet.cssRules : sheet.rules;
          if (rules)
          {
              css.push('\n/* Stylesheet : '+(sheet.href||'[inline styles]')+' */');
              for (var j=0; j<rules.length; j++)
              {
                  var rule = rules[j];
                  if ('cssText' in rule)
                      css.push(rule.cssText);
                  else
                      css.push(rule.selectorText+' {\n'+rule.style.cssText+'\n}\n');
              }
          }
      }
      var cssInline = css.join('\n')+'\n';
      var printWindow = window.open('', '_blank');
      printWindow.document.write('<html><head><title>Print</title>');
      printWindow.document.write('<link type="text/css" rel="stylesheet" href="' + sefosUrl + '/css/bootstrap.min.css" />');
      printWindow.document.write('<link rel="apple-touch-icon" sizes="180x180" href="' + sefosUrl + '/favicon/apple-touch-icon.png" />');
      printWindow.document.write('<link rel="icon" type="image/png" sizes="32x32" href="' + sefosUrl + '/favicon/favicon-32x32.png" />');
      printWindow.document.write('<link rel="icon" type="image/png" sizes="16x16" href="' + sefosUrl + '/favicon/favicon-16x16.png" />');
      printWindow.document.write('<link rel="manifest" href="' + sefosUrl + '/favicon/site.webmanifest" />');
      printWindow.document.write('<link rel="stylesheet" href="' + sefosUrl + '/css/animate.min.css" />');
      printWindow.document.write('<link href="' + sefosUrl + '/css/all.css" rel="stylesheet" />');
      printWindow.document.write('<style>' + cssInline + '</style>');
      printWindow.document.write('<style>@media print { @page {  margin: 0.3in 1in 0.3in 1in !important } }</style>');
      printWindow.document.write('</head><body>');
      printWindow.document.write(htmlContent);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  },
  computed: {
    sefos_locale() {
      return this.$store.state.lang.locale;
    },
    sefos_locales() {
      return this.$store.state.lang.locales;
    },
    user: function () {
      return this.$store.state.user;
    }
  }
})