import Vue from 'vue';
import Meta from 'vue-meta'
import Router from 'vue-router'
import routes from './routes'
import store from '../store.js';
import VueCookies from 'vue-cookies'

Vue.use(Meta);
Vue.use(Router);
Vue.use(VueCookies);

const router = createRouter();
function createRouter () {
    const router = new Router({
        mode: 'history',
        routes
    });
    router.beforeEach(beforeEach);
    router.afterEach(afterEach);
    return router
}
async function beforeEach (to, from, next) {
    if (to == "") {
        return next();
    }    
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if(requiresAuth)
    {
        await store.dispatch('user/fetchUser')
    }
    if (requiresAuth && store.getters['user/information'] === null){
      if ( (to.name !== 'Login') && (from.name != to.name) )
      {
        try {
            Vue.$cookies.set("redirect_url", to.fullPath, "3m", "", "", true, "None");        
            await store.dispatch("user/setRedirectUrl", to.fullPath);
        } catch (error) {
            ////console.log(error);
        }
      }
      //next({ name: 'Login' });
    }
    else next()
}
async function afterEach (to, from, next) {
    if (to == "") {
        console.log(to);
        return next();
    }
    await router.app.$nextTick()
}
const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router;