import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import VueCookies from 'vue-cookies'
Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
    modules: {
        user: {
            namespaced: true,
            state: () => ({
                hostname: process.env.VUE_APP_SERVER !== "" ? process.env.VUE_APP_SERVER : '/server/rest',
                information: null,
                services: null,
                new_message: 0,
                new_message_inbox: 0,
                new_notification: 0,
                functionboxes: null,
                delegatedboxes: null,
                crumbs: null,
                max_loa: null,
                redirect_url: '',
                link_token: '',
                external_loa_levels: null,
                version: '',
                loa_level_texts: null,
                loa_level_enabled: null,
                message_response_default: 1,
                message_response_enabled: 1,
                email_information_text: '',
                email_edit_information_text: 1,
                accounts_can_delegate: 0,
                loa_default_level: 0,
                auth_loa: 2,
                ticket: null,
                new_files: 0,
                drafts: 0,
                notifications: 0
            }),
            getters: {
                hostname: state => state.hostname,
                information: state => state.information,
                services: state => state.services,
                functionboxes: state => state.functionboxes,
                delegatedboxes: state => state.delegatedboxes,
                new_message: state => state.new_message,
                new_message_inbox: state => state.new_message_inbox,
                new_notification: state => state.new_notification,
                max_loa: state => state.max_loa,
                redirect_url: state => state.redirect_url,
                authenticated: state => state.information !== null,
                link_token: state => state.link_token,
                external_loa_levels: state => state.external_loa_levels,
                loa_level_texts: state => state.loa_level_texts,
                loa_level_enabled: state => state.loa_level_enabled,
                message_response_default: state => state.message_response_default,
                message_response_enabled: state => state.message_response_enabled,
                email_edit_information_text: state => state.email_edit_information_text,
                email_information_text: state => state.email_information_text,
                accounts_can_delegate: state => state.accounts_can_delegate,
                loa_default_level: state => state.loa_default_level,
                auth_loa: state => state.auth_loa,
                ticket: state => state.ticket,
                new_files: state => state.new_files,
                drafts: state => state.drafts,
                notifications: state => state.notifications
            },
            mutations: {
                ["USER_SET_VERSION"](state, payload) {
                    state.version = payload;
                },
                ["USER_SET_FILES"](state, payload) {
                    state.new_files = payload.files;
                },
                ["FETCH_USER_SUCCESS"](state, { data }) {
                    state.information = data.user;
                    state.new_message = data.new_message;
                    state.new_notification = data.new_notification;
                    state.email_information_text = data.email_information_text;
                    state.email_edit_information_text = data.email_edit_information_text;
                    state.message_response_enabled = data.message_response_enabled;                    
                    if(data.auth_loa != undefined)
                    {
                        state.auth_loa = data.auth_loa;
                    }
                    if(data.new_message_inbox != undefined){
                        state.new_message_inbox = data.new_message_inbox;
                    }
                    if(data.loa_default_level != undefined)
                    {
                        state.loa_default_level = data.loa_default_level;
                    }
                    if(data.ticket != undefined)
                    {
                        state.ticket = data.ticket;
                    }else{
                        state.ticket = {
                            auth_type: "",
                            auth_method: "",
                            loa_level: state.auth_loa,
                            sdk_enabled: 0
                        };
                    }
                    if(data.max_loa != undefined){
                        state.max_loa = data.max_loa;
                    }else{
                        state.max_loa = {
                            login: 3,
                            external: 3
                        };
                    }
                    if(data.functionboxes != undefined){
                        state.functionboxes = data.functionboxes;
                    }
                    if(data.delegatedboxes != undefined){
                        state.delegatedboxes = data.delegatedboxes;
                    }
                    if(data.services != undefined){
                        state.services = data.services;
                    }
                    if(data.external_loa_levels != undefined){
                        state.external_loa_levels = data.external_loa_levels;
                    }
                    if(data.loa_level_texts != undefined){
                        state.loa_level_texts = data.loa_level_texts;
                    }
                    if(data.loa_level_enabled != undefined){
                        state.loa_level_enabled = data.loa_level_enabled;
                    }
                    if(data.message_response_default != undefined){
                        state.message_response_default = data.message_response_default;
                    }
                    if(data.message_response_enabled != undefined){
                        state.message_response_enabled = data.message_response_enabled;
                    }
                    if(data.email_information_text != undefined){
                        state.email_information_text = data.email_information_text;
                    }
                    if(data.email_edit_information_text != undefined){
                        state.email_edit_information_text = data.email_edit_information_text;
                    }
                    if(data.accounts_can_delegate != undefined){
                        state.accounts_can_delegate = data.accounts_can_delegate;
                    }
                    if(data.drafts != undefined){
                        state.drafts = data.drafts;
                    }
                    if (data.notifications != undefined) {
                        state.notifications = data.notifications;
                    }
                },
                ["USER_SET_LINK_TOKEN"](state, { link_token }) {
                    state.link_token = link_token;
                },
                ["USER_SET_REDIRECT"](state, { redirect_url }) {
                    state.redirect_url = redirect_url;
                },
                ["LOGOUT"](state) {
                    state.information = null;
                    state.token = '';
                },
                ["FETCH_USER_FAILURE"](state) {
                    state.information = null;
                    state.token = '';
                }
            },
            actions: {
                async fetchFiles({ commit, state }) {
                    try {
                        let response = await axios.post(state.hostname + "/filestorage/new/list",
                        {
                            organisation_uuid: state.information.organisation.organisation_uuid
                        });
                        let files = response.data;
                        commit("USER_SET_FILES", { files });
                    } catch (e) {
                        commit("USER_SET_FILES", 0);
                    }
                },
                async fetchVersion({ commit, state }) {
                    if (state.hostname != "") {
                        try {
                            const { data } = await axios.get(state.hostname + "/version");                            
                            commit("USER_SET_VERSION", data);
                        } catch (e) {
                            commit("USER_SET_VERSION","");
                        }
                    }
                },
                async setLinkToken({ commit }, link_token) {
                    commit("USER_SET_LINK_TOKEN", { link_token });
                },
                async setRedirectUrl({ commit }, redirect_url) {
                    commit("USER_SET_REDIRECT", { redirect_url });                          
                },
                async fetchUser({ commit, state }) {
                    try {
                        const { data } = await axios.get(state.hostname + "/isLoggedin");
                        if(data.user != null)
                        {
                            await this.dispatch("lang/setLocale", data.user.locale);
                            if(data.token != undefined)
                            {
                                Vue.$cookies.set("token",data.token,0);
                            }
                            commit("FETCH_USER_SUCCESS", { data });
                        }else{
                            commit("FETCH_USER_FAILURE");
                        }
                    } catch (e) {
                        commit("FETCH_USER_FAILURE");
                    }
                },
                async NotAuthenticated({ commit }) { 
                    Vue.$cookies.set("token", "", "1s", '', '', true, 'None');                    
                    Vue.$cookies.remove("token"); 
                    commit("LOGOUT");
                }
            }
        },
        lang: {
            namespaced: true,
            state: ({
                locale: 'sv',
                locales: {
                    'en': 'English'
                    ,'sv': 'Svenska'
                }
            }),
            getters: {
                locale: state => state.locale,
                showAuth: state => state.auth
            },
            mutations: {
                ['LOCALE_SET'](state, { locale }) {
                    state.locale = locale
                }
            },
            actions: {
                setLocale({ commit }, locale) {
                    commit('LOCALE_SET', { locale });
                    let h = document.querySelector('html');
                    h.setAttribute('lang', locale);
                }
            }
        },
        errors: {
            namespaced: true,
            state: ({
                validations: []
            }),
            getters: {
                validations: state => state.validations
            },
            mutations: {
                ['ADD_VALIDATIONS'](state, { validations }) {
                    state.validations = validations.concat(validations)
                },
                ['REMOVE_VALIDATIONS'](state) {
                    state.validations = []
                }
            },
            actions: {
                addValidations({ commit }, validations) {
                    commit('ADD_VALIDATIONS', { validations });
                },
                removeValidations({ commit }) {
                    commit('REMOVE_VALIDATIONS');
                }
            }
        }
    }
});